.custom-navbar {
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center !important;
    height: 60px;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 200;
    background-color: aquamarine;
    padding: 0 10px;
    font-family: "Shrikhand";
    z-index: 200;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}


.nav-logo {
    display: none;
}

.navbar-nav {
    justify-content: space-around;
    width: 100%;
}

.nav-links {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
}
.nav-links a {
    text-decoration: none;
    color: black;
    
}

.link {
  flex-direction: column;
  
}

.non-icon {
    display: block;
    font-size: .7rem;
}

.non-icon::first-letter {
  text-transform: capitalize;
}

.icon {
    font-size: 2rem;
}

@media (min-width: 768px) {
  .link {
    flex-direction: row;
    
  }
  
  .non-icon {
      display: block;
      font-size: 1rem;
  }
  
  .icon {
      font-size: 2rem;
  }


}

/* Desktop view shows family name in navbar and full link words */
@media (min-width: 900px) {
    .custom-navbar {
        justify-content: space-between !important;
    }

    .navbar-expand {
        flex-wrap: wrap;
    }

    .non-icon {
      justify-content: none; 
      font-size: 1.1rem;
    }

    .nav-logo {
        display: inline;
        align-items: center;
        font-size: 1.6rem;
        padding: 0 10px;
    }
    .navbar-nav {
        justify-content: flex-end;
        max-width: 50%;
    }
    .icon {
        font-size: 2rem;
    }
}
