.initial-container {
  display: block;
  text-align: start;
  max-width: 700px;

}


.instructions {
  padding: 10px;
  display: block;

}

.instructions ol {
  text-align: start;
  font-size: large;
  font-weight: 600;
}

.instructions li {
  padding: 5px;
}

#select {
  max-width: 500px;
  
}


.chorzy-icon img {
  width: 55px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.2);
  border-radius: 5px;
}

.arrow-down-container {
  position: relative;
  display: block;
  /* bottom: 60px; */
  z-index: 0;
  width: 100%;
  margin: auto;
}

.arrow-down-container img{
  display: block;
  margin: auto;
  width: 20vh;
  height: 20vh;
  position:relative;
  max-height: 30vh;
  
}

.center-bottom {
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: baseline;
}

.front-layer-tutorial {
  position: absolute;
  flex-grow: 1;
  z-index: 0;
}

.front-layer-chores-tutorial {
  position: fixed;
  bottom: 70px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.2);
  width: 90%;
  max-width: 600px;
  z-index: 0;
}

@media (max-width: 912px) and (min-height: 1024px){

  .arrow-down-container {
    display: block;
    height: 40vh;
    z-index: 0;
    width: 100%;
    margin: auto;
  }

  .arrow-down-container img{
    display: block;
    margin: auto;
    width: 40vh;
    height: 40vh;
    position:relative;
    max-height: 50vh;
    
  }
  .front-layer-chores-tutorial {
    position: absolute;
    bottom: 70px;
    background-color: white;
    width: 50%;
    max-width: 50%;
    z-index: 0;
  }

}