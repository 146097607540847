.main {
    background-color: white;
    height: 100%;
    padding: 60px 0;
}

.spacer {
    min-height: 60px;
}
.spacer-bottom {
    bottom: 0;
    min-height: 60px;
}

.label {
    font-size: medium;
    font-weight: bold;
}
