.target-icon {
    display: inline;
    position: absolute;
    bottom: 100px;
    right: 40px;
    width: min-content;
    height: min-content;
}
.allowance-icons-container {
    display: inline;
    position: absolute;
    bottom: 100px;
    right: 40px;
    width: min-content;

}

.attitude-bonus {
  font-size: 1.6rem;
  color: teal;
  background-color: none;
  stroke-width: .6;
  overflow: visible;
  transition: 0s 1s;
}

.attitude-bonus:active {
  background-color: yellow;
  transition: 0s;
  border-radius: 50%;
}

.title {
    padding: 10px;
}

.main-button {
    background-color: aquamarine !important;
    border: aquamarine !important;
    color: black !important;
    margin: 15px 0px 15px 0px;
}

.btn-shadow {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.lifetime-earnings {
    display: none;
}
.lifetime-chores {
    display: none;
}

@media (min-width: 756px) {
    .lifetime-earnings {
        display: block;
        position: absolute;
        left: 10%;
        top: 25vh;
        width: 200px;
    }

    .lifetime-chores {
        display: block;
        position: absolute;
        right: 10%;
        top: 25vh;
        width: 200px;
    }

    .last-chore {
        display: block;
        position: absolute;
        right: 10%;
        bottom: 300px;
    }

    .lifetime-stats-header {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        font-size: 1.2rem;
    }
    .lifetime-earnings .card-header,
    .lifetime-chores .card-header {
        background-color: aquamarine;
        border-radius: 20px 20px 0 0;
    }

    .lifetime-earnings .card-header:first-child,
    .lifetime-chores .card-header:first-child {
        border-radius: 20px 20px 0 0;
    }
    .lifetime-earnings .card,
    .lifetime-chores .card {
        border-radius: 20px;
        box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
}
