.footer {
    display: flex;
    position: fixed;
    flex-wrap: wrap;
    bottom: 0px;
    left: 0px;
    height: 60px;
    width: 100%;
    background-color: rgb(127, 255, 212);
    overflow-y: hidden;
}


.member {
    flex-grow: 1;
    display: flex;
    min-width: 70px;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: bolder;
    text-align: center;
}
