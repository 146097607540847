
.chore-container {
    min-height: 100vh;
    width: 100%;
    padding-left: 10px;
    justify-content: start;

}

.chore-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 70px;
    min-width: 250px;
    width: 100%;
    height: 15%;
    font-size: 1.5rem;
    font-weight: 400;
    color: cadetblue;
    font-family: var(--chore-font);
    margin-bottom: 10px;
}


.daily-chore {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aquamarine;
  height: 100%;
  width: 80px;
  min-width: 80px;
  border-radius: 0 10px 10px 0;
  box-shadow: inset 4px 0px 4px rgba(0, 0, 0, 0.2);
}

.daily-chore-active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 80px;
  min-width: 80px;
  border-radius: 0 10px 10px 0;
  background: rgb(255, 255, 0);
  box-shadow: inset 4px 0px 4px rgba(0, 0, 0, 0.2);
}

.chore-name-value-container {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  align-items: center;
  width: 100%;
  height: 100%;
 
}

.chore-name {
  text-align: left;
  line-height: 1.8rem;
}

.chore-value {
  min-width: 90px;
  text-align: right;
}

.attitude-emoji-container {
  display: flex;
  justify-content: space-around;
  background-color: aquamarine;
  font-size: 2rem;
}

#neutral-emoji{
  background-color: rgb(107, 235, 192) 
}

.attitude-emoji {
  padding: 1rem 0;
  flex-grow: 1;
  /* background-color: aquamarine; */
}



.chore-card:active {
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(3px);
}

.main-card {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform-style: preserve-3d;
    transition: all 0.4s ease-out;
}


.card-front {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: .8rem;
    width: 100%;
    height: 100%;

    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    backface-visibility: hidden;
}

.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    transform: rotateX(180deg) rotate(.5deg);
}

.flip-card {
    transform: rotateX(180deg);
}

.completed {
    text-decoration: line-through;
    text-decoration-thickness: 1px;
    text-decoration-color: red;
    padding-left: 10px ;
}

.completed-info {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  line-height: 1;
  padding: 0 10px;
  justify-content: space-between;
  font-size: 1.7rem;
}

/* Store Card ---------------------- */

.main-store-card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 0.5s ease-out;
}


.store-card {
  font-size: 1.8rem;
  /* min-height: 350px; */
  min-width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  text-align: center;
  font-weight: 500;
  color: cadetblue;
  font-family: var(--chore-font);
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
}


.store-buy {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aquamarine;
  height: 20%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  box-shadow: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
  
}


.store-front {
  position: absolute;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 10px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
}


.store-info-container {
  text-align: start;
  padding: 2rem;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  backface-visibility: hidden;
}

.item-image-container {
  width: 100%;
 
  border-radius: 10px 10px 0 0;
}

.item-image {
  max-width: 100%;
  height: 12rem;
  object-fit: cover;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-radius: inherit;
  /* height: -webkit-fill-available; */
}

.store-name {
  display: flex;
  height: 50%;
  align-items: flex-end;
  text-align: center;
  line-height: normal;
  flex-wrap: wrap;
  padding: 10px;
  
}

.store-value {
  height: 45%;
  min-width: none;
  text-align: center;
}

.store-link {
  color: black;
  background-color: aquamarine;
  border: none;
  border-radius: 10px;
}

.bottom-right{
  position: relative;
  left: 90%;
  width: min-content;
}
.bottom-left{
  position: relative;
  left: 10%;
  width: min-content;
}




/* Increase size of font and card for ipads or larger screens */
@media (min-width: 756px) {
  .chore-container {
    min-height: 100vh;
    width: 100%;
}

.chore-card {
    font-size: 1.8rem;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 20%;
    text-align: center;
    font-weight: 500;
    color: cadetblue;
    font-family: var(--chore-font);
    margin-bottom: 15px;
}

.chore-card .attidude-emoji {
  padding: 3rem
}

.chore-card:active {
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(3px);
  
}

.main-card {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transform-style: preserve-3d;
    transition: all 0.5s ease-out;
}

.card-front {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    backface-visibility: hidden;
}

.chore-name-value-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 10px 10px 0 0;
    backface-visibility: hidden;
}


.chore-name {
  display: flex;
  height: 50%;
  align-items: flex-end;
  text-align: center;
  line-height: normal;
  flex-wrap: wrap;
  padding: 10px;
  
}

.chore-value {
  height: 45%;
  min-width: none;
  text-align: center;
}

.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
    transform: rotateY(180deg) rotate(.5deg);
}

.flip-card {
    transform: rotateY(180deg);
}

.completed {
    text-decoration: line-through;
    text-decoration-thickness: 1px;
    text-decoration-color: red;
}

.daily-chore {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aquamarine;
  height: 25%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  box-shadow: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.daily-chore-active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background: rgb(255, 255, 0);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
}
 
}
