.title-page-container {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.logo {
    border-radius: 100%;
    height: 300px;
    width: 300px;
    background-color: aquamarine;
    font-family: var(--title-font);
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    color: rgb(30, 30, 30);
}

.logo-link {
    text-decoration: none;
    color: black;
}

.logo-link:hover {
    color: black;
}

.birthdayLink {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

a:link {
    color: white;
    text-decoration: none;
}

a:visited {
    color: white;
}

.birthdayMessage {
    display: block;
    position: absolute;
    font-family: var(--chore-font);
    font-size: 1.8rem;
    line-height: 1.2;
    top: 60px;
    width: 100%;
    color: white!;
    -webkit-text-fill-color: white;
    padding: 10px;
    background-color: rgb(255, 92, 222);
    z-index: 0;
}

@media (min-width: 756px) {
    .logo {
        font-size: 3rem;
        transition: .2s all;
        box-shadow: 5px 4px 28px 1px rgba(0, 0, 0, 0.15), inset 0px 0px 3px 2px rgba(0, 0, 0, 0.18);
    }
    .logo:active {
      transform: scale(.98);
      box-shadow: 2px 1px 22px 1px rgba(0, 0, 0, 0.15), inset 0px 0px 3px 2px rgba(0, 0, 0, 0.18);
    }

    .logo div::first-letter {
      font-size: 4rem;
    }
}


