@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");
/* font-family: 'Gochi Hand', cursive; */
@import url("https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Shrikhand&display=swap");
/* font-family: 'Shrikhand', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap');
/* font-family: 'Sansita Swashed', cursive */

:root {
    --chore-font: "Gochi Hand", cursive;
    --settings-font: "Gill Sans MT", "Trebuchet MS", sans-serif;
    --title-font: "Shrikhand", cursive;
    --main-color: #7fffd4;
    /* --title-font: 'Sansita Swashed'; */
}

h1,
h2 {
  
    font-weight: bold;
    font-size: 1.5rem !important;
}

.App {
    display: flex;
    justify-content: center;
    text-align: center;
    font: size 1.2em;
    min-height: 100vh;
}

.form-label {
  margin-bottom: .25rem !important;
}