.edit-allowance-container {
    width: 100%;
    max-width: 1400px;
    min-height: 100%;
}

.edit-allowance-button {
    background-color: rgb(87, 215, 172);
    border: none;
    border-bottom: 2px solid white;
    font-size: 2rem;
    color: white;
    width: 100%;
    text-decoration: none;
}

a {
    text-decoration: none !important;
}
