.allowance-container {
    display: flex;
    flex-direction: column;
    margin: 5px auto 25px auto;
    height: 70vh;
    width: 50%;
    max-width: 100px;
    min-width: 100px;
    border: 3px solid teal;

    border-radius: 20px;
    justify-content: flex-end;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.allowance-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    position: relative;
    width: 100%;
    border-radius: 0 0 15px 15px;

    height: 0%;
    transition: height 2s;
    -webkit-transition: height 2s;
    animation: progress-bar .08s;
    -webkit-animation: progress-bar .08s;
    -moz-animation: progress-bar .08s;
}

.theme-color {
    background: linear-gradient(90deg, rgb(107, 235, 192),rgb(147, 255, 232), rgb(107, 235, 192)) ;
}

.coral-color {
    background: linear-gradient(0deg,blue, rgb(255, 133, 2)) ;
    color: white;
}

.red-color {
    background: linear-gradient(90deg, red, rgb(253, 82, 60), red) ;
    color: white;
}
.red-gradient-color {
    background: linear-gradient(0deg, red, yellow);
    color: white;
}
.black-color {
    background: linear-gradient(90deg, black, gray, black) ;
    color: white;
}
.pink-color {
    background: linear-gradient(90deg, rgb(255, 85, 165), pink, rgb(255, 85, 165)) ;
    color: white
}
.blue-color {
    background: linear-gradient(0deg, rgb(163, 25, 255), rgb(255, 85, 165)) ;
    color: white;
}
.blue-gradient-color {
    background: linear-gradient(90deg, rgb(47, 92, 255),rgb(0, 46, 237),rgb(47, 92, 255)) ;
    color: white;
}
.yellow-color {
    background: linear-gradient(0deg, rgb(170, 255, 0), yellow);
}
.teal-gradient-color {
    background: linear-gradient(0deg, aquamarine,hwb(160 80% 0%));
}


/* Fills the allowance bar slowly */
@-webkit-keyframes progress-bar {
    0% {
        height: 0;
        
    }
}
@-moz-keyframes progress-bar {
    0% {
        height: 0;
    }
}

@keyframes progress-bar {
    0% {
        height: 0;
    }
}
